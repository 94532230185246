import styled from 'styled-components';
import { colors } from '../../styles/variables';

export const StyledLink = styled.a`
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: 4;
  text-align: center;
  padding: 14px 0;
  display: block;
  background-color: ${colors.red};
  color: ${colors.white};

  &:focus {
    position: fixed;
    width: 100%;
    height: auto;
  }
`;
