import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { toggleNavigation } from '../../state/actions';
import { Context } from '../../state/store';
import Hamburger from '../Hamburger';
import SkipToContent from '../SkipToContent';
import {
  Content,
  ExternalLink,
  Logo,
  LogoLink,
  NavigationLink,
  NavigationModal,
} from './styles';

export default function Navigation({ data, logo }) {
  const [state, dispatch] = useContext(Context);
  function toggleModal() {
    dispatch(toggleNavigation());
  }

  return (
    <nav>
      <SkipToContent />
      <LogoLink
        href="/#start"
        aria-label="Gå till toppen av sidan"
        data-gtm-click="logo"
      >
        <Logo src={logo.src} alt="" aria-hidden="true" />
      </LogoLink>

      {!state.navigationIsOpen ? <Hamburger onClick={toggleModal} /> : null}

      <NavigationModal
        isOpen={state.navigationIsOpen}
        onRequestClose={toggleModal}
      >
        <Content>
          {data.map((item) => (
            <NavigationLink
              key={item.id}
              data-gtm-click={item.slug}
              href={`/#${item.slug}`}
              onClick={toggleModal}
            >
              {item.title}
            </NavigationLink>
          ))}
          <ExternalLink
            href="https://medlem.tantogarden.com"
            data-gtm-click="medlemmar"
            target="_blank"
          >
            Medlemmar
          </ExternalLink>
        </Content>
        <Hamburger onClick={toggleModal} />
      </NavigationModal>
    </nav>
  );
}

Navigation.propTypes = {
  data: PropTypes.array.isRequired,
  logo: PropTypes.object.isRequired,
};
