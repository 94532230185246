import styled from 'styled-components';
import { colors } from '../../styles/variables';

const hamburgerBase = '10px';
const left = '8px';

export const Button = styled.button`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 3;
  width: 48px;
  height: 48px;
  float: right;
  display: block;
  transform: rotate(0deg);
  transition: transform 0.5s ease-in-out;
  background-color: ${colors.white};
  border: none;
  padding: 0;
  cursor: pointer;
`;

export const Bar = styled.span`
  background-color: ${colors.blueDark};
  position: absolute;
  display: block;
  height: 3px;
  width: 30px;
  opacity: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: rotate(0deg);

  &:nth-child(1) {
    top: calc(${hamburgerBase} + 2px);
    transform-origin: left center;
    transform: ${props => (props.navigationOpen ? 'rotate(45deg)' : null)};
    left: ${props => (props.navigationOpen ? left : null)};
  }

  &:nth-child(2) {
    top: calc(${hamburgerBase} * 2 + 2px);
    transform-origin: left center;
    width: ${props => (props.navigationOpen ? 0 : null)};
    opacity: ${props => (props.navigationOpen ? 0 : null)};
  }

  &:nth-child(3) {
    transform-origin: left center;
    transform: ${props => (props.navigationOpen ? 'rotate(-45deg)' : null)};
    left: ${props => (props.navigationOpen ? left : null)};
    top: ${props =>
      props.navigationOpen
        ? `calc(${hamburgerBase} * 3 + 3px)`
        : `calc(${hamburgerBase} * 3 + 2px)`};
  }
`;
