import styled from 'styled-components';
import { fontSize } from '../../styles/variables';

export const Wrapper = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 10px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const Copyright = styled.span`
  font-size: ${fontSize.smaller};
`;

export const LogoWrapper = styled.a`
  display: block;
  margin-top: 5px;
`;

export const Logo = styled.img`
  width: 120px;
`;
