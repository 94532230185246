import PropTypes from 'prop-types';
import React from 'react';
import { Copyright, Logo, LogoWrapper, Wrapper } from './styles';

export default function Footer({ data: { logo } }) {
  return (
    <Wrapper>
      <Copyright>
        © {new Date().getFullYear()} Tantogårdens Bangolf. I samarbete med
      </Copyright>
      <LogoWrapper
        href="//www.tiekstra.se"
        data-gtm-click="tiekstra logo"
        target="_blank"
        rel="noopener"
        aria-label="Gå till Tiekstra.se"
      >
        <Logo src={logo.src} alt="" />
      </LogoWrapper>
    </Wrapper>
  );
}

Footer.propTypes = {
  data: PropTypes.object.isRequired,
};
