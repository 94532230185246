import React, { useContext } from 'react';
import { Context } from '../../state/store';
import { Bar, Button } from './styles';

export default function Hamburger({ onClick }) {
  const [state] = useContext(Context);

  return (
    <Button
      onClick={onClick}
      aria-label={
        state.navigationIsOpen ? 'Stäng navigering' : 'Visa navigering'
      }
    >
      <Bar navigationOpen={state.navigationIsOpen} />
      <Bar navigationOpen={state.navigationIsOpen} />
      <Bar navigationOpen={state.navigationIsOpen} />
    </Button>
  );
}
