import { transparentize } from 'polished';
import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { colors, fontSize } from '../../styles/variables';

ReactModal.setAppElement('#___gatsby');

const borderStyle = `
content: '';
position: absolute;
left: 0;
right: 0;
height: 1px;
background-color: ${transparentize(0.7, colors.white)};
`;

const linkStyling = `
  position: relative;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 12px;
  font-size: ${fontSize.large};
  color: ${colors.grayLight};
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background-color: ${transparentize(0.9, colors.white)};
    color: ${colors.grayLight};
  }

  &:first-child:before {
    ${borderStyle};
    top: 0; 
  }
  &::after {
    ${borderStyle};
    bottom: 0;
  }
`;

const ReactModalAdapter = ({ className, ...props }) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      aria-label="Meny"
      {...props}
    />
  );
};

export const LogoLink = styled.a`
  position: fixed;
  display: inline-block;
  height: auto;
  top: 10px;
  left: 10px;
  z-index: 3;
`;

export const Logo = styled.img`
  width: 48px;
  height: 48px;
  vertical-align: middle;
`;

export const NavigationModal = styled(ReactModalAdapter)`
  &__overlay {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: ${colors.red};
    z-index: 3;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    overflow-y: auto;
    overflow-x: hidden;

    &:focus {
      outline: 0;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NavigationLink = styled.a`
  ${linkStyling}
`;

export const ExternalLink = styled.a`
  ${linkStyling}
`;
